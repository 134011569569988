<template>
	<div>
		<a-table
			:columns="columns"
			:data-source="vehicles"
			:row-key="(record) => record.rendszam"
			size="middle"
			:pagination="{ size: 'normal' }"
		>
			<div
				slot="filterDropdown"
				slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
				style="padding: 8px"
			>
				<a-input
					v-ant-ref="(c) => (searchInput = c)"
					:placeholder="`Keresés ${column.dataIndex}`"
					:value="selectedKeys[0]"
					style="width: 188px; margin-bottom: 8px; display: block;"
					@change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
					@pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
				/>
				<a-button
					type="primary"
					icon="search"
					size="small"
					style="width: 90px; margin-right: 8px"
					@click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
				>
					Keresés
				</a-button>
				<a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">
					Visszaállítás
				</a-button>
			</div>
			<a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
			<template slot="customRender" slot-scope="text, record, index, column">
				<span v-if="searchText && searchedColumn === column.dataIndex">
					<template v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
						<mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{
							fragment
						}}</mark>
						<template v-else>{{ fragment }}</template>
					</template>
				</span>
				<template v-else>
					{{ text }}
				</template>
			</template>
			<template slot="milage" slot-scope="szerzodesid, record">
				<a-button
					icon="plus"
					shape="circle"
					class="btn-info"
					@click="showModal(record.szerzodesid, record.legnagyobboraallas)"
				/>
			</template>

			<!--  <span slot="milage">
				<a-button icon="plus" shape="circle" class="btn-info" @click="showModal(szerzodesid)" />
			</span> -->
			<template slot="vehicleFullName" slot-scope="gyartmany, record">
				{{ gyartmany }} {{ record.modell }} {{ record.tipus }}
			</template>
			<template slot="szervizintervallumFullName" slot-scope="szervizintervallumkm, record">
				{{ szervizintervallumkm | serviceIntervalTab }} km <br />
				max. {{ record.szervizintervallumhonap }} hónap
			</template>
			<template slot="userFullName" slot-scope="autohasznalonev, record">
				{{ autohasznalonev }}<br />{{ record.autohasznalotelefon }}
			</template>
		</a-table>
		<div style="text-align:center; padding-bottom:20px;">
			<download-excel
				class="ant-btn ant-btn-primary"
				propName="VEHICLE"
				:data="vehicles"
				type="csv"
				name="Járművek.csv"
				style="padding-top:5px;"
				>Mentés Excelbe</download-excel
			>
		</div>
		<a-modal v-model="visible" :footer="null" title="Km óra állás megadása">
			<a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
				<a-form-item>
					<a-input-number
						v-decorator="['milage', { rules: [{ required: true, message: 'Kérjük töltse ki a mezőt !' }] }]"
						placeholder="Pl. 12345"
						style="width:180px"
					/>
				</a-form-item>
				<a-form-item>
					<a-date-picker
						v-decorator="['date', { rules: [{ type: 'object', required: true, message: 'Kérjük válasszon dátumot !' }] }]"
						placeholder="Válasszon dátumot!"
						style="width:180px"
					/>
				</a-form-item>
				<a-button type="primary" html-type="submit" block>
					Küldés
				</a-button>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { mapState } from 'vuex';
//import Vue from 'vue';
//import excel from 'vue-excel-export';
//Vue.use(excel);

function strComp(s1, s2) {
	var i;

	for (i = 0; i < s1.length && i < s2.length; i++) {
		if (s1[i] < s2[i]) {
			return -1;
		} else if (s1[i] > s2[i]) {
			return 1;
		}
	}
	if (s1.length < s2.length) {
		return -1;
	} else if (s1.length > s2.length) {
		return 1;
	}

	return 0;
}

const columns = [
	{
		title: 'Rendszám',
		dataIndex: 'rendszam',
		rowKey: 'rendszam',
		key: 'rendszam',
		scopedSlots: {
			filterDropdown: 'filterDropdown',
			filterIcon: 'filterIcon',
			customRender: 'customRender',
		},
		onFilter: (value, record) =>
			record.rendszam
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => {
					this.searchInput.focus();
				});
			}
		},
		sorter: (a, b) => strComp(a.rendszam, b.rendszam),
	},
	{
		title: 'Hajtás',
		dataIndex: 'hajtas',
		key: 'hajtas',
		sorter: (a, b) => strComp(a.hajtas, b.hajtas),
	},
	{
		title: 'Gépjármű adatok',
		dataIndex: 'gyartmany',
		key: 'gyartmany',
		scopedSlots: { customRender: 'vehicleFullName' },
		sorter: (a, b) => strComp(a.gyartmany, b.gyartmany),
	},
	{
		title: 'Motor',
		dataIndex: 'motor',
		key: 'motor',
		sorter: (a, b) => strComp(a.motor, b.motor),
	},
	{
		title: 'Teljesítmény (kW)',
		dataIndex: 'teljesitmeny',
		key: 'teljesitmeny',
		sorter: (a, b) => a.teljesitmeny - b.teljesitmeny,
	},
	{
		title: 'Hengerűrtart. (cm3)',
		dataIndex: 'hengerurtartalom',
		key: 'hengerurtartalom',
		sorter: (a, b) => a.hengerurtartalom - b.hengerurtartalom,
	},
	{
		title: 'Forgalmi engedély száma',
		dataIndex: 'forgalmiengedelyszam',
		key: 'forgalmiengedelyszam',
		sorter: (a, b) => strComp(a.forgalmiengedelyszam, b.forgalmiengedelyszam),
	},
	{
		title: 'Műszaki érvényesség vége',
		dataIndex: 'muszakiervenyessegvege',
		key: 'muszakiervenyessegvege',
		sorter: (a, b) => strComp(a.muszakiervenyessegvege, b.muszakiervenyessegvege),
	},
	{
		title: 'Szerviz intervallum km/hónap',
		dataIndex: 'szervizintervallumkm',
		key: 'szervizintervallumkm',
		scopedSlots: { customRender: 'szervizintervallumFullName' },
		sorter: (a, b) => a.szervizintervallumkm - b.szervizintervallumkm,
	},
	{
		title: 'Gépkocsihasználó',
		dataIndex: 'autohasznalonev',
		key: 'autohasznalonev',
		scopedSlots: { customRender: 'userFullName' },
		sorter: (a, b) => strComp(a.autohasznalonev, b.autohasznalonev),
	},
	{
		title: 'Km óra állás',
		dataIndex: 'szerzodesid',
		key: 'milage',
		scopedSlots: { customRender: 'milage' },
	},
];

export default {
	// <span slot="customTitle"
	// 			><a-tooltip>
	// 				<template slot="title">
	// 					Hengerűrtartalom (ccm)
	// 				</template>
	// 				Hengerűrt. (ccm)
	// 			</a-tooltip>
	// 		</span>

	// {
	// 	dataIndex: 'hengerurtartalom',
	// 	key: 'hengerurtartalom',
	// 	slots: { title: 'customTitle' },

	// 	sorter: (a, b) => a.hengerurtartalom - b.hengerurtartalom,
	// },
	data() {
		return {
			columns,
			searchText: '',
			searchInput: null,
			searchedColumn: '',
			visible: false,
			vehicleId: null,
			mileage: '',
			form: this.$form.createForm(this, { name: 'submitMilage' }),
		};
	},

	filters: {
		serviceIntervalTab: function(value) {
			if (!value) return '';
			const value1 = new Intl.NumberFormat('hu-HU').format(value);
			return value1;
		},
	},

	created() {
		this.$store.dispatch('listVehiclesVehicleList', {
			page: 0,
			//name: this.$route.name,
		});
	},

	computed: {
		...mapState({ vehicles: (state) => state.auth.vehicleListVehicles }),
		// Other properties
	},

	methods: {
		handleSearch(selectedKeys, confirm, dataIndex) {
			confirm();
			this.searchText = selectedKeys[0];
			this.searchedColumn = dataIndex;
		},
		handleReset(clearFilters) {
			clearFilters();
			this.searchText = '';
		},
		showModal(id, mileage) {
			this.visible = true;
			this.vehicleId = id;
			this.mileage = mileage;
		},

		handleCancel() {
			this.visible = false;
		},

		handleSubmit(e) {
			e.preventDefault();
			this.form.validateFields((err, fieldsValue) => {
				if (!err) {
					if (fieldsValue['milage'] < this.mileage) {
						this.$showNotification(
							'Hiba',
							'Helytelen kilométer adatok! Az Ön által megadott kilométeróra-állás kevesebb, mint a rendszerünkben utoljára bejegyzett adat.',
							6,
							<a-icon type="close-circle" style="color:#fc0a33" />
						);
					} else {
						const values = {
							date: fieldsValue['date'].format('YYYY-MM-DD'),
							milage: fieldsValue['milage'],
						};

						this.$store
							.dispatch('setMilage', {
								id: this.vehicleId,
								milage: values.milage,
								date: values.date,
							})
							.then(() => {
								this.$showNotification(
									'Rendben',
									'A km óra állás megadása sikeresen megtörtént!',
									6,
									<a-icon type="check-circle" style="color:#00ce55" />
								);
								this.visible = false;
								this.form.resetFields();
							})
							.catch((err) => {
								this.$showNotification(
									'Hiba',
									err.response.data.detail,
									6,
									<a-icon type="close-circle" style="color:#fc0a33" />
								);
							});
					}
				}
			});
		},
	},
};
</script>

<style lang="scss">
@import '@/styles/_global.scss';
</style>

<style lang="scss" scoped>
.anticon-search {
	background: #6d8cb5!important;
	font-size: 20px !important;
	top: -3px !important;
	right: 1px !important;
}
.btn-info.ant-btn, .ant-btn-primary.ant-btn {
	background: #6d8cb5!important;
	border-color: #6d8cb5 !important;
}
</style>
